import * as Yup from "yup";

export const schemaWaitlist = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
});

export function validateEmail(value: string) {
    let error;
    if (!value) {
        error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = "Invalid email address";
    }
    return error;
}