"use client";
import { schemaWaitlist, validateEmail } from "@/app/schemas/whitelist";
import { Formik, Form, Field } from "formik";
import { useState } from "react";

const Waitlist = () => {
  const [isWaitingListed, setWaitingListed] = useState(false);
  const [isError, setError] = useState(false);

  return (
    <div className="row waitlist-content">
      <div className="col-twelve">
        <h2 className="h01 animate-this">
          Join the waitlist to become one of the first to experience
        </h2>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={schemaWaitlist}
          onSubmit={async (values, { resetForm }) => {
            const res = await fetch("/whitelist", {
              method: "POST",
              body: JSON.stringify({ email: values.email }),
            });
            const data = await res.json();
            if (!data?.error) {
              setWaitingListed(true);
              setError(false);
              resetForm();
            } else {
              setWaitingListed(false);
              setError(true);
            }
          }}
        >
          {({ errors, touched }) => (
            <Form id="mc-form" className="group" autoComplete="off">
              <div className="row">
                <div
                  className="col-eight tab-full left animate-this"
                  data-animate="fadeInLeft"
                >
                  <Field
                    type="email"
                    name="email"
                    className="email"
                    id="mce-EMAIL"
                    placeholder="Enter Your Email"
                    validate={validateEmail}
                  />
                  {errors.email && touched.email ? (
                    <div className="subscribe-message">{errors.email}</div>
                  ) : null}
                </div>
                <div
                  className="col-four tab-full right animate-this"
                  data-animate="fadeInRight"
                >
                  <input
                    type="submit"
                    value="Join Waitlist"
                    name="subscribe"
                    className="button large"
                  />
                </div>
              </div>
              {isWaitingListed && (
                <label htmlFor="mce-EMAIL" className="subscribe-message">
                  Thank you for joining our waiting list! You&apos;re now among
                  the first to be informed about exciting updates.
                </label>
              )}
              {isError && (
                <label
                  htmlFor="mce-EMAIL"
                  className="subscribe-message"
                  style={{ color: "red" }}
                >
                  Oops, it seems there was an issue with your subscription.
                </label>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default Waitlist;
